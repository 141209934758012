html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #000;
}

@font-face {
    font-family: "Star Wars";
    src: url("./fonts/SF-Distant-Galaxy-Outline.ttf");
}

@font-face {
    font-family: "Star Wars Full";
    src: url("./fonts/SF-Distant-Galaxy.ttf");
}

@font-face {
    font-family: "Mona Sans";
    src: url("./fonts/Mona-Sans-Regular.woff");
}

@font-face {
    font-family: "Mona Sans Bold";
    src: url("./fonts/Mona-Sans-Bold.woff");
}

@font-face {
    font-family: "Mona Sans Light";
    src: url("./fonts/Mona-Sans-Light.woff");
}

@tailwind base;
@tailwind components;
@tailwind utilities;
