.App {
    width: 100vw;
    min-height: 100vh;
    /* background-color: #1b1f24; */
    color: #fff;
}

.content-area {
    max-width: 988px;
    margin: 0 auto;
    height: 100%;
}

.section {
    min-height: 300px;
}

.svg-child {
    height: 120px;
}

@keyframes FadeIn {
    0% {
        opacity: 0;
        transform: translateX(40px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.animated-entry {
    animation: FadeIn 2s ease calc(4s * 2);
}
